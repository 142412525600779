export default {
  formTitle: "Finanzsanierung",
  formSubTitle: "Einfach und unkompliziert",
  heading: "Schnelle Auszahlung",
  subHeading: "100% Kostenlos und Unverbindlich",
  notice: `Finanzsanierungen sind gefragter denn je!

  Überzeugen Sie sich von unseren Fähigkeiten! Wir vermitteln selbst schwierigste Fälle und passen uns Ihrer individuellen Situation an.`,
  itemsTitle: "Eine Finanzsanierung bietet Ihnen viele Vorteile:",
  items: [
    "Antrag garantiert 100% kostenlos & unverbindlich",
    "Unbürokratisch, schnell und auf Sie abgestimmt",
    "Finanzsanierungen auch für Ehe- oder Lebenspartner",
    "Unsere Kundenbetreuung berät Sie kompetent in allen Lebenslagen",
    "Vorentscheid erfolgt direkt online ohne Wartezeiten!",
    "Experten mit langjähriger Erfahrung stehen an Ihrer Seite",
    "Abwicklung auf dem Postweg - sparen Sie sich lästige Gänge zu Beratern oder Banken",
    "Bank- oder Beratertermine entfallen für Sie",
  ],
  tncText: "*kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
  btnText: "Jetzt Antrag erstellen*",
  repsTitle: "REPRÄSENTATIVEN BEISPIEL",
  repsExample: `*Angaben gem. § 6a PAngV: <br/><br/>Unveränderlicher Sollzinssatz ab 3,92%, effektiver Jahreszins ab 3,99% - 15,99%, Nettodarlehensbetrag ab 1.000,- bis 50.000,- €, Laufzeit von 12 bis 120 Monaten, Bonität vorausgesetzt. Bei einem Nettodarlehensbetrag von 10.000,- € und einer Laufzeit von 36 Monaten erhalten 2/3 der Kunden von Sofort24Kredit vorraussichttlich einen eff. Zinssatz von 8,90% oder günstiger (geb. Sollzinssatz 5,26%)."`,
}
