import React, { ReactElement } from "react"
import PageContainer from "../hoc/page"
import DefaultLayout from "../layouts/default"
import Data from "../data/financial-restructuring"
import "./style.css"
import CommonPointsContainer from "../hoc/common-points"
import ApplyContainer from "../hoc/apply"
import HelmetContainer from "../hoc/helmet"
function Index({}: any): ReactElement {
  return (
    <>
      <DefaultLayout>
      <ApplyContainer title={Data.formTitle} subTitle={Data.formSubTitle} />
        <HelmetContainer pageTitle="Finanzsanierung"/>
        <CommonPointsContainer data={Data} />
      </DefaultLayout>
    </>
  )
}

export default Index
